@font-face {
  font-family: Courgette;
  src: url('./fonts/Courgette.ttf');
  font-display: swap;
}

@font-face {
  font-family: DancingScript;
  src: url('./fonts/DancingScript.ttf');
  font-display: swap;
}

@font-face {
  font-family: Pacifico;
  src: url('./fonts/Pacifico.ttf');
  font-display: swap;
}

@font-face {
  font-family: Playball;
  src: url('./fonts/Playball.ttf');
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: arial;
  font-size: 15px;
  text-rendering: optimizelegibility;
  letter-spacing: -0.25px;
}

body {
  background: #000000;
}

h1 {
  color: #ffffff;
  font-family: "Pacifico";
  font-size: 32px;
}

h2 {
  color: #ffffff;
  font-family: "DancingScript";
  font-size: 32px;
}

h3 {
  color: #ffffff;
}

p {
  color: #ffffff;
  font-family: "Courgette";
  font-size: 24px;
  line-height: 2.1;
  font-weight: 400;
}

ol {
  padding-left: 50px;

  li:nth-child(odd):before {
    border-color: #0BAD02;
  }

  li:nth-child(even):before {
    border-color: #2378D5;
  }
}

li {
  color: #00e1ff;
  padding-left: 16px;
  margin-top: 24px;
  position: relative;
  font-size: 26px;
  line-height: 20px;

  &:before {
    content: '';
    display: block;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: 2px solid #ddd;
    position: absolute;
    top: -12px;
    left: -46px;
  }
}


ul li {
  color: #ffffff;
  font-family: "Courgette", sans-serif;
  list-style: none;
  padding-left: 50px;
  position: relative;
}

ul li::before {
  position: absolute;
  width: 0;
  height: 0;
  left: 16px;
  top: 6px;
  border-top: 9px solid transparent;
  border-right: 9px solid #f9dd94;
  transform: rotate(10deg);
}

ul li::after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 3px solid #ff0000;
  border-radius: 20px 0 0 0;
  top: 0;
  left: -5px;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
}

strong {
  font-size: 24px;
  color: #00e1ff;
  font-family: "Courgette", sans-serif;
}

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-image {
  margin-bottom: 1em;
  height: 92px; /* Original size */
  filter: drop-shadow(0 0 20px #0033cc); /* Brighter and more intense glow */
  animation: pulseGlow 4s ease-in-out infinite;
  transition: filter 0.4s ease, transform 0.3s ease;
}

@keyframes pulseGlow {
  0% {
    filter: drop-shadow(0 0 20px #0033cc) drop-shadow(0 0 20px #0033cc); /* Brighter starting glow */
    transform: scale(1);
  }
  50% {
    filter: drop-shadow(0 0 35px #0033cc) drop-shadow(0 0 35px #0033cc); /* More intense glow at midpoint */
    transform: scale(1.02); /* Slight scale up for a subtle pulsing effect */
  }
  100% {
    filter: drop-shadow(0 0 20px #0033cc) drop-shadow(0 0 20px #0033cc); /* Return to brighter glow */
    transform: scale(1);
  }
}

.logo-image:hover {
  filter: drop-shadow(0 0 40px #0033cc); /* Stronger glow on hover */
  transform: scale(1.05); /* Gently enlarge the logo when hovered */
}

.top-row,
.bottom-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.top-row ul,
.bottom-row ul {
  display: flex;
  list-style: none;
}

.top-row li,
.bottom-row li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  margin: 2px;
  border-radius: 4px;
  padding-left: 0px;
  transition: transform 0.3s ease;

  &:after {
    border-top: none;
  }

  &:before {
    border: none;
  }
}

.top-row li:hover,
.bottom-row li:hover {
  transform: scale(1.1);
}

@media (max-width: 480px) {

  .top-row li,
  .bottom-row li {
    height: 80px;
    width: 65px;
  }
}

.top-row li:hover .shortcut-image,
.bottom-row li:hover .shortcut-image {
  background: #00d9ff;
  color: #1e1e1e;
}

.top-row a,
.bottom-row a {
  text-decoration: none;
  color: white;
  font-size: 0.9rem;
  background: none;
}

@media (max-width: 480px) {

  .top-row a,
  .bottom-row a {
    font-size: 0.7rem
  }
}

.shortcut-image {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: #00d9ff;
  background: #313133;
  border-radius: 50%;
  margin-bottom: 10px;
  transition: background 0.3s ease, color 0.3s ease;
}

.tree {
  display: grid;
  place-items: center;
}

.tree ul {
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  margin-top: 0px;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li::before {
  border: none;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
  transform: none;
  left: 0px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 20px 45px;
  text-decoration: none;
  color: #00FFFF;
  font-family: "Courgette", sans-serif;
  font-size: 24px;
  display: inline-block;

  border-radius: 38px;
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

@media only screen and (max-width: 930px) {
  .tree li a {
    font-size: 16px;
    padding: 10px 15px;
  }
}

.tree li a:hover,
.tree li a:hover+ul li a {
  background: radial-gradient(circle, var(--c-radial-inner), var(--c-radial-outer) 80%);
  color: #fff;
  --c-radial-inner: #6D58FF;
  --c-radial-outer: #362A89;
  box-shadow: 0 0 20px #00FFFF;
  margin: 0 20px;
}

.tree li a:hover+ul li::after,
.tree li a:hover+ul li::before,
.tree li a:hover+ul::before,
.tree li a:hover+ul ul::before {
  border-color: #00FFFF;
}

.section-heading > h2 {
  font-weight: 300;
  color: #5b32b4;
  font-size: 48px;
  margin: 0;
  text-align: center
}

.section-heading {
  margin-bottom: 60px;
}

.line-shape {
  width: 80px;
  height: 2px;
  background-color: #fb397d;
  margin-top: 15px;
  margin-left: calc(50% - 40px);
}

span {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.media-1 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 28px;
}

.media-1:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 150, 0.8);
  content: "";
  transition: .3s all ease;
  opacity: 0;
  visibility: hidden;
}

.media-1 img {
  object-fit: contain;
  transition: .3s all ease;
}

.media-1 .media-1-content {
  z-index: 4;
  top: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.media-1 .media-1-content h2 {
  color: #fff;
  font-size: 1.5rem;
  transition: .4s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  bottom: -10px;
  margin-bottom: 0px;
  font-family: inherit;
}

.media-1 .media-1-content .category {
  position: relative;
  transition: .3s all ease-in-out;
  opacity: 0;
  bottom: -10px;
  visibility: hidden;
  color: #ff6666;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .2em;
}

.media-1:hover:after {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.media-1:hover .category {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
  transition-delay: 0.09s;
}

.media-1:hover h2 {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
  background: transparent;
}

.media-1:hover img {
  transform: scale(1.05);
}

.product-list > * {
  width: 50%;
  float: left;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .product-list > * {
      width: 100%;
      float: none;
      margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .product-list > * {
    width: 33.33%;
  }
}

@media (min-width: 1200px) {
  .product-list > * {
    width: 25%;
  }
}

.carousel-slide {
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.review-slide {
  padding: 20px;
  text-align: center;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #fff;
}
